import React from 'react';

const Blog2 = () => {
    return (
       <div>
  <meta charSet="UTF-8" />
  <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Healthrek | Files</title>
  <title>Healthrek | Storage come Hospital Website</title>
  {/* Page Icon */}
  <link
    rel="shortcut icon"
    href="image/heartbeat-solid.svg"
    type="image/x-icon"
  />
  {/* font-awesome */}
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
  />
  {/* Custom Css File Link */}
  <link rel="stylesheet" href="../css/style.css" />
  {/* Add any additional meta tags or links as needed for this specific page */}
  <section>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      textAlign: "center",
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 24,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif',
          
        }}
      >
        EHR Unveiled: Navigating the Technical and Philosophical Dimensions of
        Healthcare's Digital Frontier
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",serif'
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 21,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        Introduction:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      In the ever-evolving landscape of healthcare, Electronic Health Records
      (EHR) stand as the vanguard of a digital revolution. This blog seeks to
      unravel the intricate tapestry of EHR, exploring its technical features,
      delving into philosophical perspectives, examining the challenges and
      successes of implementation, and peering into the promising future it
      paints for healthcare.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif'
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 21,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        Technical Features of EHR:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        1. Interoperability:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      &nbsp; &nbsp;EHR systems leverage interoperability, allowing seamless data
      exchange among different healthcare entities. This ensures that patient
      information is readily available, fostering collaborative and holistic
      care.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        2. Data Security and Privacy:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',color:'black'
      }}
    >
      &nbsp; &nbsp;Robust encryption, access controls, and audit trails are
      integral components of EHRs, ensuring the confidentiality and integrity of
      patient data. These features address concerns related to data breaches and
      unauthorized access.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        3. Structured Data and Standardized Formats:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      &nbsp; &nbsp;EHRs organize information in structured formats, enabling
      consistent data entry and facilitating data analysis. Standardized
      formats, such as HL7 and CDA, enhance interoperability and information
      exchange.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        4. Decision Support Systems:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      &nbsp; &nbsp;EHRs integrate decision support systems powered by artificial
      intelligence, aiding healthcare professionals in clinical decision-making
      by providing insights, alerts, and recommendations based on patient data.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif'
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 21,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        Philosophical Views on EHR:
      </span>
    </strong>
  </p>
  <div
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <ol style={{ marginBottom: "0cm", listStyleType: "decimal" }}>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8.0pt",
          marginLeft: "0cm",
          fontSize: "11.0pt",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        <strong>
          <span style={{ fontSize: 16 }}>Patient-Centered Care:</span>
        </strong>
      </li>
    </ol>
  </div>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      EHRs embody a philosophical shift towards patient-centered care.
      Empowering patients with access to their own health records fosters
      transparency, shared decision-making, and active participation in their
      healthcare journey.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        2. Holistic Healthcare Approach:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      &nbsp; &nbsp;EHRs support a holistic approach to healthcare by
      consolidating comprehensive patient information. This enables healthcare
      providers to consider the entirety of a patient's health history, leading
      to more informed and personalized care.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        3. Data as a Catalyst for Innovation:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      &nbsp; &nbsp;Viewing data as a valuable resource, EHRs fuel healthcare
      innovation. The ability to analyze large datasets can uncover patterns,
      trends, and insights that contribute to medical research, public health
      initiatives, and advancements in healthcare delivery.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif'
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 21,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        Implementation Challenges and Successes:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        1. User Training and Change Management:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      &nbsp; &nbsp;Successful EHR implementation requires comprehensive user
      training and effective change management strategies. Resistance to change
      can be mitigated through education and support, ensuring a smooth
      transition for healthcare professionals.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        2. Financial Considerations:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      &nbsp; &nbsp;The initial costs of implementing EHR systems can be
      substantial. However, the long-term benefits, including reduced
      administrative burdens, improved efficiency, and better patient outcomes,
      often outweigh the upfront investment.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        3. Interoperability Struggles:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      &nbsp; &nbsp;Achieving seamless interoperability among diverse EHR systems
      remains a challenge. Standardization efforts, such as FHIR (Fast
      Healthcare Interoperability Resources), aim to address these challenges by
      creating a universal framework for data exchange.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif'
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 21,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        The Future Road of EHR:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        1. Continued Innovation:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      &nbsp; &nbsp;EHRs will continue to evolve with advancements in
      technologies such as blockchain, machine learning, and natural language
      processing. These innovations will enhance the functionality, security,
      and efficiency of EHR systems.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        2. Population Health Management:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      &nbsp; &nbsp;EHRs will play a pivotal role in population health
      management, aiding healthcare providers in proactively addressing health
      trends, preventing diseases, and improving overall community well-being.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 16,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        3. Enhanced Patient Engagement:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif',
        color:'black'
      }}
    >
      &nbsp; &nbsp;The future of EHR involves further empowering patients
      through increased access to their health data, telehealth integrations,
      and personalized health management tools, fostering a more engaged and
      informed patient population.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <span
      style={{
        fontSize: 16,
        lineHeight: "115%",
        fontFamily: '"Calibri",sans-serif'
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      fontSize: "11.0pt",
      fontFamily: '"Calibri",sans-serif',
      lineHeight: "115%"
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 21,
          lineHeight: "115%",
          fontFamily: '"Calibri",sans-serif'
        }}
      >
        Conclusion:
      </span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: 16, fontFamily: '"Calibri",sans-serif',color:'black' }}>
      Electronic Health Records represent not just a technological upgrade but a
      paradigm shift in healthcare. With a robust blend of technical
      sophistication, patient-centered philosophy, and ongoing innovation, EHRs
      are steering the healthcare industry towards a future where information is
      not just stored but actively utilized to elevate the quality and
      efficiency of patient care. The journey from philosophical ideals to
      technical intricacies underscores the transformative potential of
      Electronic He
    </span>
  </p>
  <p>
    <br />
  </p>
  </section>
  {/* Blogs section End */}
  {/* Content for the Files page goes here */}
  {/* Header Section Starts */}
  
  {/* Header Section End */}
  {/* Footer section Starts  */}
  <section className="footer">
    <div className="box-container">
      <div className="box">
        <h3>quick links</h3>
        <a href="./index2.html">
          {" "}
          <i className="fas fa-chevron-right" /> Home
        </a>
        <a href="./patients.html">
          {" "}
          <i className="fas fa-chevron-right" /> Patients
        </a>
        <a href="./about.html">
          {" "}
          <i className="fas fa-chevron-right" /> About
        </a>
        <a href="./review.html">
          {" "}
          <i className="fas fa-chevron-right" /> Review
        </a>
        <a href="./blogs.html">
          {" "}
          <i className="fas fa-chevron-right" /> Blogs
        </a>
        <a href="#">
          {" "}
          <i className="fas fa-chevron-right" /> Stored files
        </a>
      </div>
      <div className="box">
        <h3>Our services</h3>
        <a href="#">
          {" "}
          <i className="fas fa-chevron-right" /> Storage
        </a>
        <a href="#">
          {" "}
          <i className="fas fa-chevron-right" /> Consultation
        </a>
        <a href="#">
          {" "}
          <i className="fas fa-chevron-right" /> Massage therapy
        </a>
        <a href="#">
          {" "}
          <i className="fas fa-chevron-right" /> Cardioloty
        </a>
        <a href="#">
          {" "}
          <i className="fas fa-chevron-right" /> Diagnosis
        </a>
        <a href="#">
          {" "}
          <i className="fas fa-chevron-right" /> Ambulance service
        </a>
      </div>
      <div className="box">
        <h3>Contact info</h3>
        <a href="#">
          {" "}
          <i className="fas fa-phone" /> +91-9967814533
        </a>
        <a href="#">
          {" "}
          <i className="fas fa-phone" /> 080-35648102
        </a>
        <a href="#">
          {" "}
          <i className="fas fa-envelope" /> healthrek09.info.com
        </a>
        <a href="#">
          {" "}
          <i className="fas fa-envelope" /> healthrek09@gmail.com
        </a>
        <a href="#">
          {" "}
          <i className="fas fa-map-marker-alt" /> Bangalore, Karnataka-98.
        </a>
      </div>
      <div className="box">
        <h3>follow us</h3>
        <a href="#">
          {" "}
          <i className="fab fa-facebook-f" /> facebook
        </a>
        <a href="#">
          {" "}
          <i className="fab fa-twitter" /> twitter
        </a>
        <a href="#">
          {" "}
          <i className="fab fa-linkedin" /> linkedin
        </a>
        <a href="#">
          {" "}
          <i className="fab fa-instagram" /> instagram
        </a>
        <a href="#">
          {" "}
          <i className="fab fa-youtube" /> youtube
        </a>
        <a href="#">
          {" "}
          <i className="fab fa-pinterest" /> pinterest
        </a>
      </div>
    </div>
    <div className="credit">
      Created by <span> Healthrek Associates </span> | all right reserved
    </div>
  </section>
  {/* Footer section End  */}
  {/* js to be added */}
  {/*  js file link  */}
</div>
    );
}

export default Blog2;
