// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './pages/Navbar';
import Home from './pages/Home';
import Files from './pages/Files';
import About from './pages/About';
import Doctors from './pages/Doctors';
import Medicine from './pages/Medicine';
import Review from './pages/Review';
import Blogs from './pages/Blogs';
import Logout from './pages/Logout';
import Blog1 from './pages/blog1';
import Blog2 from './pages/blog2';
import Blog3 from './pages/bolg3';

function App() {
  return (
    <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/files" element={<Files />} />
          <Route path="/about" element={<About />} />
          <Route path="/doctors" element={<Doctors />} />
          <Route path="/medicine" element={<Medicine />} />
          <Route path="/review" element={<Review />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog1" element={<Blog1 />} />
          <Route path="/blog2" element={<Blog2 />} />
          <Route path="/blog3" element={<Blog3 />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
    </Router>
  );
}

export default App;
