import React from 'react';
const Review = () => {
    return (
        <div>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <h1 class="heading">Logged out Successfully</h1>
        </div>
    );
};

export default Review;
